<template>
  <div class="footer">
    <div class="footer__top">
      <a
        v-for="(link, idx) in footerLinks"
        :id="getGtmId(link.label)"
        :key="idx"
        class="footer__link"
        @click="!link.modal ? linkTo(link.href, link.target) : $emit('modal', link.modal)"
      >
        {{ $t(link.label) }}
      </a>
      <a
        :id="getGtmId('footer_link_cookies')"
        class="ot-sdk-show-settings footer__link"
        @click="$emit('cookies')"
      >
        {{ $t('cookies_link_label') }}
      </a>
    </div>
    <div class="footer__sub">
      <div class="footer__sub-inner">
        {{ $t('footer_copy') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',

  computed: {
    footerLinks() {
      return this.safeGet(APP_CONFIG, 'footer.links');
    },
  },

  methods: {
    linkTo(link, target) {
      window.open(link, target);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  z-index: 12;
  max-width: 100%;

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__sub {
    padding: .5rem;
    font-size: $font-size-xs;
    font-family: $font-family-bold;
    background: $color-darkgrey;
    color: white;
    text-align: center;
    letter-spacing: 0.7px;

    @include breakpoint(medium) {
      padding: 1.325rem;
      font-size: $font-size-s;
    }

    &-inner {
      max-width: 200px;
      margin: auto;

      @include breakpoint(medium) {
        max-width: none;
        width: 100%;
      }
    }
  }

  &__link,
  // override onetrust style
  #ot-sdk-btn.ot-sdk-show-settings,
  #ot-sdk-btn.optanon-show-settings#ot-sdk-btn {
    cursor: pointer;
    padding: 1rem 0.5rem;
    font-size: $font-size-xxs;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: white;
    overflow: hidden;
    text-align: center;
    border: none;

    &:hover {
      background-color: transparent;
    }

    @include breakpoint(medium) {
      letter-spacing: .2em;
    }
  }
}
</style>
