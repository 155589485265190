/*eslint-disable */
const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/; secure; SameSite=Lax`;
};

const getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const getAllCookies = () => document.cookie.split(';');

const eraseCookie = name => {
  // determine the domain on which GA has set its cookies;
  // this will only work if the effective TLD is the same as the
  // actual TLD (does work for `.de`, `.com`, etc. but not for
  // `.co.uk`. `.com.br`
  //
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id#automatic_cookie_domain_configuration
  const siteHostname = document.location.hostname.split('.');
  let cookieDomain;
  if (siteHostname.length > 1) {
    cookieDomain = `.${siteHostname.slice(-2).join('.')}`;
  } else {
    cookieDomain = document.location.hostname;
  }

  document.cookie = `${name}=; Max-Age=0; Path=/; domain=${cookieDomain}`;
};

export default {
  setCookie,
  getCookie,
  eraseCookie,
  getAllCookies,
};
