<template lang="html">
  <header class="header">
    <div class="header__title-container">
      <icon
        image="logo"
        class="header__logo"
      />
      <div v-if="isWebinar">
        {{ safeGet(room, 'name') }}
      </div>
    </div>

    <action-nav
      v-if="isWebinar"
      :active-item="sidebarOpen ? sidebarActiveTab : null"
      class="actionnav--header"
      @select="onSelectNavItem"
    />
  </header>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import Icon from '@/components/common/Icon';

export default {
  name: 'Header',

  components: {
    Icon,
  },

  data: () => ({
    error: '',
  }),

  computed: {
    ...mapState('AWCMedia', ['isMediaSupported']),
    ...mapState(['participation', 'room', 'nickname', 'sidebarOpen', 'sidebarActiveTab']),
    ...mapGetters('AWCContacts', ['me']),
    ...mapState('AWCView', ['showHardwareSettings']),
    ...mapGetters(['canSendVideo', 'canSendAudio',
      'canChangeName', 'attendeeVideoPermissions', 'attendeeAudioPermissions',
    ]),

    isWebinar() {
      return this.$route.name === 'Webinar';
    },
  },

  methods: {
    ...mapMutations(['setSidebarOpen', 'setSidebarActiveTab']),

    onSelectNavItem(key) {
      if (this.$mq !== 'mobile') return;

      if (this.sidebarOpen && this.sidebarActiveTab === key) {
        this.setSidebarOpen(false);
        return;
      }
      this.setSidebarActiveTab(key);
      if (!this.sidebarOpen) this.setSidebarOpen(true);
    },
  },
};
</script>

<style lang="scss" scoped>

.header {
  height: $header-height--mobile;
  background-size: cover;
  padding: .5rem 1.5rem;
  position: relative;
  background: linear-gradient(to right, #020204, #45464f 50%, #020204);
  z-index: 11;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  transition: all .5s ease !important;
  overflow: hidden;
  flex-shrink: 0;

  @include breakpoint(medium) {
    height: $header-height;
    font-size: 12px;
    display: flex;
  }

  &__logo {
    width: 60px;
    z-index: 1;
    position: absolute;
    left: 0;
    max-height: 100%;

    @include breakpoint(medium) {
      width: 120px;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    text-align: center;
    justify-content: center;
    padding: 0 calc(50px + 1rem);

    @include breakpoint(small only) {
      margin-top: 5px;
    }

    @include breakpoint(medium) {
      width: 100%;
      padding: 0 calc(8rem + 1rem);
    }

    > div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

</style>
