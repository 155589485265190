/*eslint-disable */
import util from './cookieUtil';
import i18n from '@/util/i18n';

const cookieDetails = {
  functional: {
    groupName: i18n.tc('cookiemanager.details_functional_cookies_groupname'),
    moreInfoName: i18n.tc('cookiemanager.details_functional_cookies_moreinfoname'),
    copy: i18n.tc('cookiemanager.details_functional_cookies_copy'),
    cookies: [
      {
        name: 'session',
        copy: i18n.tc('cookiemanager.details_functional_cookies_session_copy'),
        expiration: i18n.tc('cookiemanager.details_functional_cookies_session_expiration'),
      },
      {
        name: 'cookies_\u200Baccepted',
        copy: i18n.tc('cookiemanager.details_functional_cookies_cookies_accepted_copy'),
        expiration: i18n.tc('cookiemanager.details_functional_cookies_cookies_accepted_expiration'),
      },
      {
        name: 'dw_\u200BTechnical_\u200Bcookie',
        copy: i18n.tc('cookiemanager.details_functional_cookies_cookies_dw_technical_copy'),
        expiration: i18n.tc('cookiemanager.details_functional_cookies_cookies_dw_technical_expiration'),
      },
      {
        name: 'dw_\u200BAdvertisement_\u200Bcookie',
        copy: i18n.tc('cookiemanager.details_functional_cookies_cookies_dw_advertisement_copy'),
        expiration: i18n.tc('cookiemanager.details_functional_cookies_cookies_dw_advertisement_expiration'),
      },
      {
        name: 'cookies\u200BCheckGDPR',
        copy: i18n.tc('cookiemanager.details_functional_cookies_cookies_check_gdpr_copy'),
        expiration: i18n.tc('cookiemanager.details_functional_cookies_cookies_check_gdpr_expiration'),
      },
    ],
  },
  performance: {
    groupName: i18n.tc('cookiemanager.details_performance_cookies_groupname'),
    moreInfoName: i18n.tc('cookiemanager.details_performance_cookies_moreinfoname'),
    copy: i18n.tc('cookiemanager.details_performance_cookies_copy'),
    cookies: [],
  },
  tracking: {
    groupName: i18n.tc('cookiemanager.details_tracking_cookies_groupname'),
    moreInfoName: i18n.tc('cookiemanager.details_tracking_cookies_moreinfoname'),
    copy: i18n.tc('cookiemanager.details_tracking_cookies_copy'),
    cookies: [
      {
        name: '_ga',
        copy: i18n.tc('cookiemanager.details_tracking_cookies_ga_copy'),
        expiration: i18n.tc('cookiemanager.details_tracking_cookies_ga_expiration'),
      },
      {
        name: '_gid',
        copy: i18n.tc('cookiemanager.details_tracking_cookies_gid_copy'),
        expiration: i18n.tc('cookiemanager.details_tracking_cookies_gid_expiration'),
      },
      {
        name: '_gat',
        copy: i18n.tc('cookiemanager.details_tracking_cookies_gat_copy'),
        expiration: i18n.tc('cookiemanager.details_tracking_cookies_gat_expiration'),
      },
    ],
  },
};

const cookieNames = {
  tech: 'dw_Technical_cookie',
  ad: 'dw_Advertisement_cookie',
};

const setCookies = ({ tech, ad }) => {
  const settings = getCookies();

  if (settings.tech !== tech) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'cookieSettings',
        cookieType: 'performance',
        cookieValue: tech ? 'opt-in' : 'opt-out', // or ‘opt-in’
      });
    }
  }

  if (settings.ad !== ad) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'cookieSettings',
        cookieType: 'advertising',
        cookieValue: ad ? 'opt-in' : 'opt-out',
      });
    }
  }

  util.setCookie(cookieNames.tech, tech ? 'opt-in' : 'opt-out', 90);
  util.setCookie(cookieNames.ad, ad ? 'opt-in' : 'opt-out', 90);
  setAccepted(ad || tech ? '1' : '0');
};
const setAccepted = accepted => {
  util.setCookie('cookies_accepted', accepted, 0);
  util.setCookie('cookiesCheckGDPR', accepted, 365);
};

const getCookies = () => ({
  tech: util.getCookie(cookieNames.tech) === 'opt-in',
  ad: util.getCookie(cookieNames.ad) === 'opt-in',
});

// return true if both cookies are present
const cookiesPresent = () => (
  util.getCookie(cookieNames.tech) !== null
    && util.getCookie(cookieNames.ad) !== null
);

const setInitialCookies = () => {
  util.setCookie(cookieNames.tech, 'opt-out', 90);
  util.setCookie(cookieNames.ad, 'opt-out', 90);
};

export default {
  cookieDetails,
  setInitialCookies,
  setCookies,
  cookiesPresent,
  getCookies,
};
