<template>
  <div class="cookie-manager">
    <cookie-overlay
      v-if="showCookieOverlayDecision"
      @accept="acceptAll"
      @cookie-settings="openCookieSettings"
      @close="close"
    />
    <transition name="modal">
      <div
        v-if="internalShowSettings || showCookieSettings"
        class="modal"
      >
        <cookie-settings-modal @close="setTracking" />
      </div>
    </transition>
  </div>
</template>

<script>

import CookieOverlay from './CookieOverlay.vue';
import CookieSettingsModal from './CookieSettingsModal.vue';
import cookies from './cookies';

export default {
  name: 'CookieManager',
  components: {
    CookieOverlay,
    CookieSettingsModal,
  },
  props: {
    /**
     * Required to identify the cookies in the local storage
     * Should be different for each Website
     */
    cookieKey: {
      type: String,
      required: true,
    },
    /**
     * A way to forcely display the cookie overlay via Prop
     */
    showCookieOverlay: {
      type: Boolean,
      default: false,
    },
    /**
     * A way to forcely display the cookie settings via Prop
     */
    showCookieSettings: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to true will enable Google Tag Manager (if the Library vue-gtm is present)
     */
    handleGTM: {
      type: Boolean,
      default: false,
    },
    /**
     * Delay initial cookie setting (for async loaded srcs, e.g. matomo)
     */
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      internalShowOverlay: localStorage.getItem(this.cookieKey) == null,
      internalShowSettings: false,
    };
  },
  computed: {
    showCookieOverlayDecision() {
      // locally controlled || prop based
      return this.internalShowOverlay || this.showCookieOverlay;
    },
  },

  mounted() {
    const { ad } = cookies.getCookies();
    setTimeout(() => this.execAnalytics(ad), this.delay);
    // this.execGTM(tech)
  },
  methods: {
    execAnalytics(tracking = null) {
      if (tracking === null) return;
      if (!this.$matomo) return;
      if (tracking) {
        this.$matomo.forgetUserOptOut();
        this.$matomo.rememberConsentGiven();
      } else {
        this.$matomo.optUserOut();
      }
    },
    execGTM(performance = null) {
      if (performance === null) return;
      if (!this.handleGTM) return;
      this.$gtm.enable(performance);
      this.$emit('tracking-performance', performance);
    },
    setTracking({ tracking, performance }) {
      cookies.setCookies({ tech: tracking, ad: performance });
      this.execAnalytics(performance);

      if (tracking || performance) {
        localStorage.setItem(this.cookieKey, 'true');
      }
      localStorage.setItem('overlayAccepted', '1');
      this.close();
    },
    close() {
      this.internalShowOverlay = false;
      this.internalShowSettings = false;
      this.$emit('close');
    },
    acceptAll() {
      this.setTracking({ tracking: true, performance: true });
      localStorage.setItem(this.cookieKey, 'true');
      this.$emit('accept-all');
    },
    openCookieSettings() {
      this.internalShowSettings = true;
    },
  },
};
</script>
