<template>
  <div
    class="modal-wrapper"
    :class="{ 'modal-wrapper--dark': dark }"
    @click="backdropClose ? close() : null"
  >
    <div
      class="modal__container"
      :class="[`modal__container--${width}`]"
    >
      <div
        class="modal"
        :class="[`modal--${width}`, { transparent, centered }]"
      >
        <div
          v-if="closable"
          class="modal__close"
          @click="close()"
        >
          <icon image="close" />
        </div>
        <div
          class="modal__inner"
          @click.stop.prevent
        >
          <icon
            v-if="icon"
            :image="icon"
            class="modal__icon"
          />

          <div class="modal__header">
            <slot name="header" />
          </div>

          <div class="modal__body">
            <slot />

            <div
              v-if="confirmLabel"
              class="modal__buttons"
            >
              <app-button
                :id="getGtmId('modal_confirm')"
                :label="confirmLabel"
                type="primary"
                :arrow="buttonArrow"
                @click="confirm()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/Button';
import Icon from '@/components/common/Icon';

export default {
  name: 'Modal',

  components: {
    AppButton,
    Icon,
  },

  props: {

    confirmLabel: {
      type: String,
      default: '',
    },

    buttonArrow: {
      type: Boolean,
      default: true,
    },

    icon: {
      type: String,
      default: '',
    },

    width: {
      type: String,
      default: 'medium',
    },

    closable: {
      type: Boolean,
      default: true,
    },

    backdropClose: {
      type: Boolean,
      default: false,
    },

    transparent: {
      type: Boolean,
      default: false,
    },

    centered: {
      type: Boolean,
      default: false,
    },

    dark: {
      type: Boolean,
      default: false,
    },
  },

  methods: {

    close() {
      this.$emit('close');
    },

    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/_app.scss';

  .modal-wrapper {
    z-index: 20;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    background: linear-gradient(180deg, rgba(0,0,0,0.5), rgba(0,0,0,0.9));

    &--dark {
      background: linear-gradient(180deg, rgba(0,0,0,0.8), rgba(0,0,0,1.0));
    }
  }

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 3rem 1.75rem;
    color: $text-color;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    max-height: calc(100vh - 1rem);

    @include breakpoint(medium) {
      height: auto;
      padding: 2rem 1.75rem;
      max-height: calc(100vh - 8rem);
    }

    &__container {
      padding: 0.5rem;
      position: relative;
      margin: auto;
      max-width: 100%;

      @include breakpoint(medium) {
        padding: 4rem;
      }

      &--large {
        width: 100%;
        padding: 0;
      }
    }

    /deep/ a {
      word-break: break-all;
      text-decoration: underline;
    }

    &.transparent {
      background: transparent;

      @include breakpoint(medium) {
        padding: 2rem;
      }

      .modal__close {
        @include breakpoint(medium) {
          color: white;
        }
      }
    }

    &.centered {
      text-align: center;

      @include breakpoint(small only) {
        padding: 3rem 0.5rem;
      }

      .modal__icon {
        margin: auto;
      }

      .button {
        min-width: 40%;
        width: auto;
      }
    }

    &--small {
      max-width: 450px;

      @include breakpoint(medium) {
        width: 50vw;
      }
    }

    &--medium {

      @include breakpoint(large) {
        width: 70vw;
        max-width: 1000px;
      }
    }

    &--large {
      max-height: calc(100vh - 1rem);
      padding: 0;

      &.centered,
      &.transparent {
        padding: 0;
      }
    }


    &__inner {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    &__header {
      font-size: $font-size-m;
      margin-bottom: 1rem;
      padding: 0 1rem;

      @include breakpoint(medium) {
        font-size: $font-size-l;
      }

      &:empty {
        display: none;
      }
    }

    &__body {
      z-index: 1;
      padding: 0 1rem;
      font-size: $font-size-xs;
      overflow-y: auto;
      overflow-x: hidden;

      @include scrollbar();
    }

    &__icon {
      margin-right: 1.5rem;
      margin-bottom: 1rem;
      width: 45px;
      height: 45px;
    }

    &__buttons {
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0 0;
      margin-top: 1rem;

      .button {
        width: 100%;
        justify-content: center;
      }
    }

    &__close {
      cursor: pointer;
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      padding: 1.25rem;
      z-index: 2;
      color: $color-darkergrey;
    }
  }
</style>
