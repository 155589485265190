
import * as Sentry from '@sentry/browser';

const log = (message, ...args) => {
  try {
    console.log(`[${new Date().toISOString()}] ${message}`, JSON.stringify(args));
  } catch (e) {
    console.log(`[${new Date().toISOString()}] ${message}`, args);
  }
};

const logError = (err, ...args) => {
  /* eslint-disable no-console */
  if (err.name) console.error(`${err.name}: ${err.message}`);
  else console.error(err, ...args);
  Sentry.captureException(err, { extra: args });
  /* eslint-enable no-console */
};


export { log, logError };
