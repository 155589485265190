import axios from 'axios';

const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

const requestHeaders = token => ({
  Authorization: `Bearer ${token}`,
});

export default {
  enlistParticipation(token) {
    const headers = requestHeaders(token);
    return request.post('/enlist', {}, { headers });
  },

  checkNicknameFree(token, nickname) {
    const headers = requestHeaders(token);
    return request.post('/nickname-free', { nickname }, { headers });
  },

  updateParticipation(token, data) {
    const headers = requestHeaders(token);
    return request.put('/update', data, { headers });
  },

  publishPermanence(token, seconds) {
    const headers = requestHeaders(token);
    return request.post('/track-permanence', { token, seconds }, { headers });
  },

  $http: axios,
};
