import Vue from 'vue';
import browserDetect from 'vue-browser-detect-plugin';
import VueBrowserUpdate from '@sum.cumo/vue-browserupdate';
import VueMq from 'vue-mq';
import VueMatomo from 'vue-matomo';
import VueGtm from '@gtm-support/vue2-gtm';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import AvaConference from 'ava-conference';
import AvaChat from 'ava-chat';
import App from '@/App';
import i18n from '@/util/i18n';
import themeStyles from '@/util/themes';
import GlobalMixin from '@/mixins/global';
import TrackingMixin from '@/mixins/tracking';

import router from '@/router';
import store from '@/store';

import { logError } from '@/util/logger';

const sentryDsn = process.env.VUE_APP_SENTRY_DSN;

router.beforeEach((to, from, next) => {
  const { enlistToken, participation } = store.state;
  // if the next view requires a participation & we don't have one...
  if (to.meta.participation && !participation) {
    if (enlistToken) return next({ name: 'Entry', query: { token: enlistToken } });
    return next({ name: 'ThankYou' });
  }

  return next();
});

router.afterEach(to => {
  document.title = to.meta.title || i18n.t('thank_you_for_joining');
});

if (sentryDsn) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    release: process.env.npm_package_version,
    dsn: sentryDsn,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });
}

Vue.use(AvaConference, {
  store,
  apiUrl: process.env.VUE_APP_API_URL,
  video3qPlayerUrl: process.env.VUE_APP_3QPLAYER_URL,
  sentryDsn,
  i18n,
  mixins: [
    GlobalMixin,
    TrackingMixin,
  ],
});

Vue.use(AvaChat, {
  store,
  sentryDsn,
  i18n,
});

Vue.use(VueMq, {
  breakpoints: {
    mobile: 920,
    medium: 1024,
    large: Infinity,
  },
});

if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    debug: process.env.NODE_ENV !== 'production',
    vueRouter: router,
    defer: true,
    enabled: true,
  });
}

if (process.env.VUE_APP_MATOMO_TRACKING_SITE_ID) {
  Vue.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_TRACKING_URL,
    siteId: process.env.VUE_APP_MATOMO_TRACKING_SITE_ID,
    router,
    requireConsent: true,
    trackInitialView: true,
    debug: false,
  });
  // eslint-disable-next-line
  window._paq.push(['setSecureCookie', true]);
}

Vue.use(VueBrowserUpdate, {
  options: {
    required: {
      e: 0,
      f: 0,
      o: 0,
      o_a: 0,
      s: 0,
      c: 0,
      ios: 0,
      samsung: 0,
    },
    shift_page_down: 0,
    text: {
      msg: i18n.t('browserupdate.notice.msg', ['{brow_name}']),
      msgmore: i18n.t('browserupdate.notice.msgmore'),
      bupdate: i18n.t('browserupdate.notice.bupdate'),
      bignore: i18n.t('browserupdate.notice.bignore'),
      remind: i18n.t('browserupdate.notice.remind', ['{days}']),
      bnever: i18n.t('browserupdate.notice.bnever'),
    },
    url: 'browser-update',
    newwindow: true,
    no_permanent_hide: true,
  },
  containerAsync: true,
});

Vue.use(browserDetect);

Vue.config.productionTip = false;

Vue.config.errorHandler = err => {
  logError(err);
};

new Vue({
  router,
  i18n,
  store,
  components: { App },
  render: h => h(App),
}).$mount('#app');

// load themed styles
themeStyles();
