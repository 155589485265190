<template>
  <div class="cookie-info">
    {{ copy || null }}
    <div
      v-if="cookies.length > 0"
      class="more-info"
      @click="showMore"
    >
      <accordion>
        <div slot="header">
          {{ $t('cookiemanager.info_headline', { type: moreInfoName }) }}
        </div>
        <div class="more-info-copy">
          <table v-if="cookies.length > 0">
            <thead>
              <tr>
                <th>{{ groupName }}</th>
                <th>{{ $t('cookiemanager.info_use') }}</th>
                <th>{{ $t('cookiemanager.info_expirationdate') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cookie, idx) in cookies"
                :key="idx"
              >
                <td>{{ cookie.name }}</td>
                <td>{{ cookie.copy }}</td>
                <td>{{ cookie.expiration }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </accordion>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/common/Accordion';

export default {
  name: 'CookieInfo',

  components: {
    Accordion,
  },

  props: {
    groupName: {
      type: String,
      default: '',
    },
    moreInfoName: {
      type: String,
      default: '',
    },
    copy: {
      type: String,
      default: '',
    },
    cookies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showMoreInfo: false,
    };
  },
  methods: {
    showMore() {
      this.showMoreInfo = !this.showMoreInfo;
    },
  },
};
</script>

<style lang="scss">

.cookie-info {
  margin-bottom: 35px;
  letter-spacing: 1.2px;
  font-weight: normal;
  font-size: 10px;
  line-height: 1.7;

  @include breakpoint(medium) {
    letter-spacing: 1.4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.42;
  }
  .more-info {
    margin: 15px 0;
    font-weight: bold;
  }

  .more-info-copy {
    overflow: auto;
    width: 100%;
    height: auto;
    text-align: left;

    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 10px;
      hyphens: auto;

      th {
        padding-bottom: 10px;
        border-bottom: 1px solid #000;
        vertical-align: top;
        &:first-child {
          @include breakpoint(medium) {
            width: 20%;
          }
        }
        &:last-child {
          @include breakpoint(medium) {
            width: 15%;
          }
        }
      }
      td {
        padding: 10px 0;
        vertical-align: top;
      }
      td:not(:last-child),
      th:not(:last-child) {
        padding-right: 10px;

        @include breakpoint(small only) {
          padding-right: 5px;
        }

        @include breakpoint(medium) {
          padding-right: 20px;
        }
      }
      tbody tr:last-child td {
        padding-bottom: 0;
      }
    }
  }
}
</style>
