<template>
  <modal
    class="cookie-settings"
    @close="close"
  >
    <div slot="header">
      {{ $t('cookiemanager.settings_headline') }}
    </div>
    <div class="container">
      <p class="cookie-info intro-text">
        {{ $t('cookiemanager.settings_intro_text') }}
      </p>
      <div class="setting">
        <span class="label">{{ $t('cookiemanager.settings_functional_cookies_headline') }}</span>
        <toggle
          disabled
          :value="true"
        />
      </div>
      <cookie-info v-bind="functionalCookies" />

      <div class="setting">
        <span class="label">{{ $t('cookiemanager.settings_performance_cookies_headline') }}</span>
        <toggle
          :value="performance"
          @input="changePerformance"
        />
      </div>
      <cookie-info v-bind="performanceCookies" />

      <div class="setting">
        <span class="label">{{ $t('cookiemanager.settings_tracking_cookies_headline') }}</span>
        <toggle
          :value="tracking"
          @input="changeTracking"
        />
      </div>
      <cookie-info v-bind="trackingCookies" />
      <div style="margin-bottom: 1rem">
        <span class="label">{{ $t('cookiemanager.settings_what_are_cookies_headline') }}</span>
      </div>
      <div class="cookie-info">
        {{ $t('cookiemanager.settings_what_are_cookies_text') }}
      </div>
      <div class="legal-links">
        <a
          :id="getGtmId('cookie_settings_modal_privacy')"
          class="link"
          href="https://www.pmiprivacy.com/de-de/consumer"
          target="_blank"
          rel="noopener"
        >{{ $t('cookiemanager.settings_privacy_link_label') }}</a>
      </div>
    </div>
  </modal>
</template>

<script>
import Toggle from '@/components/common/Toggle';
import Modal from '@/components/common/Modal';
import CookieInfo from './CookieInfo.vue';
import cookies from './cookies';

export default {
  name: 'CookieSettingsModal',
  components: {
    Modal,
    Toggle,
    CookieInfo,
  },
  data() {
    const cookieVals = cookies.getCookies();
    const cookieValue = {
      tracking: cookieVals.tech,
      performance: cookieVals.ad,
    };
    return {
      ...cookieValue,
      cookiesInfo: cookies.cookieDetails,
    };
  },
  computed: {
    functionalCookies() {
      return cookies.cookieDetails.functional;
    },
    performanceCookies() {
      return cookies.cookieDetails.performance;
    },
    trackingCookies() {
      return cookies.cookieDetails.tracking;
    },
  },
  methods: {
    changeTracking(val) {
      this.tracking = val;
    },
    changePerformance(val) {
      this.performance = val;
    },
    close() {
      this.$emit('close', {
        tracking: this.tracking,
        performance: this.performance,
      });
    },
  },
};
</script>
