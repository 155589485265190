const getCurrentTimeAsString = () => {
  const date = new Date();
  const [yearMonthDay, time] = date.toJSON().slice(0, -1).split('T');
  const [, ...minSecs] = time.split(':');
  minSecs.unshift(date.getHours());

  return `${yearMonthDay}T${minSecs.join(':')}`;
};

export default getCurrentTimeAsString;
