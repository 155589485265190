import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { merge } from 'lodash';
import { logError } from '@/util/logger';

Vue.use(VueI18n);

const supportedLocals = process.env.VUE_APP_I18N_SUPPORTED_LOCALES || '';

export const getSupportedLanguage = locale => {
  if (!locale) return null;
  const lang = locale.substring(0, 2);
  return process.env.VUE_APP_I18N_SUPPORTED_LOCALES.includes(lang) ? lang : null;
};

function loadMessages(locales) {
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    // always load default locale & load all locales if no locales defined
    // do not load locale if not supported
    if (process.env.VUE_APP_I18N_DEFAULT_LOCALE !== matched[1]
      && !supportedLocals.includes(matched[1]) && supportedLocals.length
    ) return;
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function loadLocaleMessages() {
  return loadMessages(require.context('@/locales/', true, /[A-Za-z0-9-_,\s]+\.json$/i));
}

function loadThemeMessages() {
  let messages = {};
  try {
    messages = loadMessages(require.context('@theme-dir/locales/', true, /[A-Za-z0-9-_,\s]+\.json$/i));
  } catch {
    logError('theme: translations not found for', process.env.VUE_APP_THEME);
  }
  return messages;
}

export default new VueI18n({
  locale: getSupportedLanguage(navigator.language) || process.env.VUE_APP_I18N_DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_DEFAULT_LOCALE || 'en',
  messages: merge(loadLocaleMessages(), loadThemeMessages()),
});
