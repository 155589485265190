<template>
  <div :class="['accordion', { open, full }]">
    <div
      class="accordion__header"
      @click="open = !open"
    >
      <slot name="header" />
      <div class="accordion__header-arrow" />
    </div>

    <transition name="slide-up-down">
      <div v-if="open">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'Accordion',

  props: {
    full: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    open: false,
  }),

};

</script>

<style lang="scss" scoped>

.accordion {

  &__header {
    cursor: pointer;
    position: relative;
    padding: .5rem 0;
    display: flex;
    align-items: center;
  }

  &__header-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-left: 5px;
    transition: transform .2s ease;

    &:after {
      content: "";
      display: block;
      border: solid $color-gold;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: .25em;
      transform: rotate(45deg);
      margin-top: -.3em;
    }
  }

  &.full &__header-arrow {
    margin-left: auto;
  }

  &.open &__header-arrow {
    transform: rotateX(180deg);
  }

}

</style>
