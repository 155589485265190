<template>
  <button
    :class="[
      `button button--${type}`,
      { 'button--arrow': arrow },
      { 'button--small': small },
      { 'button--centered': centered },
    ]"
    @click="$emit('click', $event)"
  >
    <icon
      v-if="icon"
      :image="icon"
      class="button__icon"
    />
    <icon
      v-if="arrow"
      class="button__icon-before"
      image="arrow"
    />
    <div class="button__inner">
      {{ label }}
    </div>
    <icon
      v-if="arrow"
      class="button__icon-after"
      image="arrow"
    />
  </button>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  name: 'Button',

  components: {
    Icon,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'primary',
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
