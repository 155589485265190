import get from 'lodash/get';
import { log, logError } from '@/util/logger';
import { parseCookies } from '@/util/cookies';

const gtmPrefix = process.env.VUE_APP_GTM_PREFIX;

export default {
  data: () => ({
    gtmPrefix,
  }),
  computed: {
    gtmData() {
      const cookies = parseCookies();
      const consent = cookies.dw_Technical_cookie === 'opt-in';
      const gtmEnabled = this.$gtm && this.$gtm.enabled();
      // eslint-disable-next-line
      const gtmClientId = cookies._gid || false;
      return {
        ready: consent && gtmEnabled && gtmClientId,
        gtmClientId,
      };
    },
  },
  methods: {
    safeGet: get,
    log,
    logError,
    getGtmId(element) {
      return `${this.gtmPrefix ? `${this.gtmPrefix}_` : ''}${element}`;
    },
  },
};
