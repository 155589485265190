import { logError } from '@/util/logger';

const themeStyles = () => {
  try {
    // eslint-disable-next-line
    require('@theme-dir/assets/scss/_app.scss');
  } catch {
    logError(`theme: styles not found for ${process.env.VUE_APP_THEME}`);
  }
};

export default themeStyles;
