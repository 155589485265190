import Vue from 'vue';
import Router from 'vue-router';
import i18n from '@/util/i18n';

const Entry = () => import('@/pages/Entry');
const Webinar = () => import('@/pages/Webinar');
const Page404 = () => import('@/pages/404');
const Error = () => import('@/pages/Error');
const ThankYou = () => import('@/pages/ThankYou');
const BrowserUpdate = () => import('@/pages/BrowserUpdate');
const OneTrustCookiePage = () => import('@/pages/OneTrustCookiePage');

const Validation = () => import('@/pages/Validation');

Vue.use(Router);

const routes = [
  {
    path: '/webinar/:roomID',
    component: Webinar,
    name: 'Webinar',
    meta: { footer: false, layout: 'webinar', participation: true, title: i18n.t('entry_welcome_message') },
  },
  {
    path: '/login',
    component: Entry,
    name: 'Entry',
    meta: { footer: true, layout: 'intro', participation: false, title: i18n.t('entry_welcome_message') },
  },
  {
    path: '/thankyou',
    component: ThankYou,
    name: 'ThankYou',
    meta: { footer: true, layout: 'info', participation: false, title: i18n.t('thank_you_for_joining') },
    props: true,
  },
  {
    path: '*',
    component: Page404,
    name: '404',
    meta: { footer: true, layout: 'info', participation: false, title: i18n.t('entry_welcome_message') },
  },
  {
    path: '/error',
    component: Error,
    name: 'Error',
    meta: { footer: true, layout: 'info', participation: false, title: i18n.t('entry_welcome_message') },
  },
  {
    path: '/browser-update',
    component: BrowserUpdate,
    name: 'BrowserUpdate',
    meta: { footer: true, layout: 'info', participation: false, title: i18n.t('entry_welcome_message') },
  },
  {
    path: '/onetrust',
    component: OneTrustCookiePage,
    name: 'OneTrustCookiePage',
  },
];

if (process.env.VUE_APP_VALIDATION_ACTIVE === 'true') {
  routes.push({
    path: '/validate',
    name: 'Validation',
    component: Validation,
    meta: { footer: false, layout: 'entry', participation: false },
  });
}

export default new Router({
  base: process.env.BASE_URL || '/',
  mode: 'history',
  routes,
});
